import React, { useEffect, useState } from 'react';
import { useAPI } from '../../utils/api';
import { showLoading, dismissLoading } from '../../utils/loading';
import * as Scroll from 'react-scroll';
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
import { useSelector } from 'react-redux';
import store, { actions, getters } from '../../redux/store';

import ContentLoader from 'react-content-loader';

const PAGE_CACHE_KEY = 'category-list';

const CategoryPreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 390;
  const ratio = viewWidth / mainWidth;
  return (
    <>
      <ContentLoader
        viewBox={`0 0 ${390 * ratio} 60`}
        height={60}
        width={390 * ratio}
      >
        {[...Array(6)].map((i, index) => {
          return (
            <>
              <rect
                x={8 * ratio + index * 110}
                y={8}
                rx="8"
                ry="8"
                width={100 * ratio}
                height="25"
              />
            </>
          );
        })}
      </ContentLoader>
    </>
  );
};

const SubCategoryPreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 390;
  const ratio = viewWidth / mainWidth;
  return (
    <>
      <ContentLoader
        viewBox={`0 0 ${358 * ratio} 26`}
        height={26}
        width={358 * ratio}
      >
        {[...Array(6)].map((i, index) => {
          return (
            <>
              <rect
                x={index * 70}
                y={0}
                rx="12"
                ry="12"
                width={60 * ratio}
                height="26"
              />
            </>
          );
        })}
      </ContentLoader>
    </>
  );
};

const scrollToMainElement = (id) => {
  const element = document.querySelector(`#category-item-${id}`);
  scroller.scrollTo(`category-item-${id}`, {
    duration: 1000,
    delay: 100,
    smooth: true,
    containerId: 'category-block',
    offset: -window.innerWidth / 2 + element.offsetWidth / 2,
    horizontal: true,
  });
};
const scrollToSubElement = (id) => {
  const element = document.querySelector(`#option-item-${id}`);
  scroller.scrollTo(`option-item-${id}`, {
    duration: 1000,
    delay: 100,
    smooth: true,
    containerId: 'option-block',
    offset: -window.innerWidth / 2 + element.offsetWidth / 2,
    horizontal: true,
  });
};

const CustomMainElement = ({
  id,
  name,
  categoryIndex,
  setCategoryIndex,
  getSubCategory,
}) => {
  return (
    <>
      <div
        name={`category-item-${id}`}
        id={`category-item-${id}`}
        className={`category-item ${id === categoryIndex ? 'active' : ''}`}
        key={id}
        onClick={() => {
          setCategoryIndex(id);
          getSubCategory(id);

          scrollToMainElement(id);
        }}
      >
        {name}
      </div>

      <style jsx>
        {`
          .category-item {
            padding: 13px 11px 7px 11px;
            font-size: 14px;
            line-height: 20px;
            color: #3b3516;
            flex-shrink: 0;
            border-bottom: 1px solid #e3e3e3;
            &.active {
              font-weight: bold;
              border-bottom: 1px solid #5fd2da;
            }
          }
        `}
      </style>
    </>
  );
};

const CustomSubElement = ({
  id,
  name,
  optionIndex,
  setOptionIndex,
  onRefresh,
}) => {
  return (
    <>
      <div
        id={`option-item-${id}`}
        name={`option-item-${id}`}
        className={`option-item ${id === optionIndex ? 'active' : ''}`}
        key={id}
        onClick={() => {
          setOptionIndex(id);
          onRefresh(id, name);

          scrollToSubElement(id);
        }}
      >
        {name}
      </div>

      <style jsx>
        {`
          .option-item {
            padding: 4px 15px;
            flex-shrink: 0;
            background: #ffffff;
            border: 1px solid #e3e3e3;
            box-sizing: border-box;
            border-radius: 30px;
            font-size: 14px;
            line-height: 20px;
            color: #3b3516;
            &.active {
              font-weight: bold;
              background: #5fd2da;
              border: 1px solid #5fd2da;
              color: #ffffff;
            }
          }
          .option-item ~ .option-item {
            margin-left: 8px;
          }
        `}
      </style>
    </>
  );
};

const TabBar = ({ onRefresh, cateId }) => {
  const api = useAPI();

  const [categories, setCategories] = useState(undefined);
  const [subcategories, setSubCategories] = useState(undefined);
  const [categoryIndex, setCategoryIndex] = useState('');
  const [optionIndex, setOptionIndex] = useState('');
  const currentStoreId = useSelector((state) => getters.getStoreId(state));

  const localCacheData = useSelector((state) => getters.getLocalCache(state));

  const getSubCategory = (id) => {
    if (categories.length > 0) {
      categories.map((element) => {
        if (id === element.id) {
          setSubCategories(element.sub);
          setOptionIndex(element.sub[0].id);
          onRefresh(element.sub[0].id, element.sub[0].name);
          setTimeout(() => {
            scrollToSubElement(element.sub[0].id);
          }, 200);
        }
      });
    }
  };

  useEffect(() => {
    const cateCache = localCacheData[`${PAGE_CACHE_KEY}-${currentStoreId}`];

    if (cateCache !== undefined && cateCache !== null) {
      setTimeout(() => {
        setCategories(cateCache);
        cateCache.map((element) => {
          if (cateId === element.id) {
            setCategoryIndex(cateId);
            setSubCategories(element.sub);
            setOptionIndex(element.sub[0].id);
            onRefresh(element.sub[0].id, element.sub[0].name);

            scrollToMainElement(cateId);
          }

          if (element.sub.length > 0) {
            for (const e of element.sub) {
              if (cateId === e.id) {
                setCategoryIndex(element.id);
                setSubCategories(element.sub);
                setOptionIndex(e.id);
                onRefresh(e.id, e.name);

                scrollToMainElement(element.id);
                scrollToSubElement(e.id);
              }
            }
          }
        });
      }, 500);

      return;
    }
    // showLoading();
    api
      .getCategories(currentStoreId)
      .then((res) => {
        dismissLoading();
        if (!res) {
          callAppFunc('storeClose', {});
          return;
        }
        if (res.length > 0) {
          console.log('cateId', cateId);
          // set cache
          const cacheData = {
            key: `${PAGE_CACHE_KEY}-${currentStoreId}`,
            data: res,
          };
          store.dispatch(actions.addLocalCache(cacheData));

          setCategories(res);
          res.map((element) => {
            if (cateId === element.id) {
              setCategoryIndex(cateId);
              setSubCategories(element.sub);
              setOptionIndex(element.sub[0].id);
              onRefresh(element.sub[0].id, element.sub[0].name);

              scrollToMainElement(cateId);
            }

            if (element.sub.length > 0) {
              for (const e of element.sub) {
                if (cateId === e.id) {
                  setCategoryIndex(element.id);
                  setSubCategories(element.sub);
                  setOptionIndex(e.id);
                  onRefresh(e.id, e.name);

                  scrollToMainElement(element.id);
                  scrollToSubElement(e.id);
                }
              }
            }
          });
        }
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  }, []);

  const MainElement = Scroll.ScrollElement(CustomMainElement);
  const SubElement = Scroll.ScrollElement(CustomSubElement);
  return (
    <>
      <div className="tab-bar">
        <div
          name="category-block"
          id="category-block"
          className="category-block"
        >
          {categories !== undefined ? (
            categories.map((item, idx) => (
              <MainElement
                key={item.id}
                id={item.id}
                name={item.name}
                categoryIndex={categoryIndex}
                setCategoryIndex={setCategoryIndex}
                getSubCategory={getSubCategory}
              ></MainElement>
              // <div
              //   name={`category-item-${item.id}`}
              //   id={`category-item-${item.id}`}
              //   className={`category-item ${
              //     item.id === categoryIndex ? 'active' : ''
              //   }`}
              //   key={item.id}
              //   onClick={() => {
              //     setCategoryIndex(item.id);
              //     getSubCategory(item.id);
              //   }}
              // >
              //   {item.name}
              // </div>
            ))
          ) : (
            <CategoryPreview />
          )}
        </div>
        <div name="option-block" id="option-block" className="option-block">
          {subcategories !== undefined ? (
            subcategories.map((item, idx) => (
              // <div
              //   className={`option-item ${
              //     item.id === optionIndex ? 'active' : ''
              //   }`}
              //   key={item.id}
              //   onClick={() => {
              //     setOptionIndex(item.id);
              //     onRefresh(item.id, item.name);
              //   }}
              // >
              //   {item.name}
              // </div>
              <SubElement
                key={item.id}
                id={item.id}
                name={item.name}
                optionIndex={optionIndex}
                setOptionIndex={setOptionIndex}
                onRefresh={onRefresh}
              ></SubElement>
            ))
          ) : (
            <SubCategoryPreview />
          )}
        </div>
      </div>
      <style jsx>{`
        ::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        .tab-bar {
          background-color: #ffffff;
          .category-block,
          .option-block {
            display: flex;
            justify-content: flex-start;
          }

          .category-block {
            height: 40px;
            overflow-x: auto;
            box-sizing: border-box;
            .category-item {
              padding: 13px 11px 7px 11px;
              font-size: 14px;
              line-height: 20px;
              color: #3b3516;
              flex-shrink: 0;
              border-bottom: 1px solid #e3e3e3;
              &.active {
                font-weight: bold;
                border-bottom: 1px solid #5fd2da;
              }
            }
          }
          .option-block {
            padding: 8px 16px 1px 16px;
            overflow-x: auto;
            box-sizing: border-box;
            .option-item {
              padding: 4px 15px;
              flex-shrink: 0;
              background: #ffffff;
              border: 1px solid #e3e3e3;
              box-sizing: border-box;
              border-radius: 30px;
              font-size: 14px;
              line-height: 20px;
              color: #3b3516;
              &.active {
                font-weight: bold;
                background: #5fd2da;
                border: 1px solid #5fd2da;
                color: #ffffff;
              }
            }
            .option-item ~ .option-item {
              margin-left: 8px;
            }
          }
        }
      `}</style>
    </>
  );
};

export default TabBar;
